const getPositions = (str, rules) => rules.map((aRule) => {
    const found = str.indexOf(aRule[0])
    return [...aRule, found]
})
.filter((aResult) => aResult[3] > -1)
.sort((a, b) => a[3] - b[3])

const splitter = (stack, str, rules) => {
    const indexes = getPositions(str, rules)
    // BASE CASE
    if (indexes.length === 0) {
        if (stack.top) {
            // case for broken tag (forgot to add last ** or something)
            const found = rules.find((aRule) => aRule[0] === stack.top)
            if (found) {
                const endTag = found[2]
                return str + endTag
            }
        }
        return str
    }
    // no rule exists (base case)
    const [markup, startTag, endTag, position] = indexes[0]
    const newStr = [
        str.slice(0, position),
        str.slice(position),
    ]
    // modify stack so we know if this is an end or beginning tag
    if (stack.top !== markup) {
        // not the same tag
        newStr[1] = newStr[1].replace(markup, startTag)
        stack.push(markup)
    } else {
        newStr[1] = newStr[1].replace(markup, endTag)
        stack.pop()
    }
    return splitter(stack, newStr.join(''), rules)
}

const main = (note) => {
    if (!note) return ''
    const stack = {
        top: null,
        items: [],
        push(v) {
            this.top = v
            this.items.unshift(v)
        },
        pop() {
            this.items.shift()
            this.top = this.items[0] || null
        }
    }
    const STEPS = [
        ['**', '<dnote>', '</dnote>'],
        ['*', '<knote>', '</knote>'],
    ]
    return splitter(stack, note, STEPS)
}

const testCases = () => {
    console.log('========== TEST ============')
    const arr = [
        'This is atest',
        'This is a **dnote**',
        'This is a *knote*',
        'This is a **dnote** *knote*',
        'This is a *knote* **dnote**',
        'This is a broken *knote',
        'This is a broken **dnote *knote*'
    ]
    for (let i = 0; i < arr.length; i += 1) {
        console.log(main(arr[i]))
    }
}

export default main
