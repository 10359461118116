// sample
// after splitting delims, you end with the format
// [startOfParagraph, sentence, restOfParagraph]
const prepareParagraph = (sourceText, relevantText, replaceWith) => sourceText.replace(relevantText, replaceWith)

// after splitting delims, you end with the format
// [startOfParagraphHTML, sentenceWithHTML, restOfParagraphHTML]
const addInHTML = () => {

}

// will always be the FIRST empty string (there maybe multiple if no text in paragraph after sentence)
const findSentenceIndex = (arr) => arr.findIndex((str) => str === '')

const sentenceHTML = (word, sentence) => {
    const startTag = `<span style="border-bottom:1px solid; padding-bottom:3px;">`
    const endTag = `</span>`
    const wordHTML = (text) => `<span class="badge bg-purple" style="font-size:100%">${text}</span>`
    const newSentence = `${startTag}${sentence}${endTag}`
    // the case that word was removed at end of sentence ???
    if (newSentence.search(word) !== -1) return newSentence.replace(word, wordHTML(word))
    // word length is greater than 1
    if (word.length > 1) {
        const temp = word.substring(0, word.length - 1)
        return newSentence.replace(temp, wordHTML(temp))
    }
    // word length is exactly 1?
    return newSentence
}

const nonSentenceHTML = (text) => `<span style="opacity: .75">${text}</span>`

export default (data) => {
    const DELIM = '!@#$'
    // prep
    return data.map((aSentence) => {
        const { paragraph, sentence, val: wordToHighlight } = aSentence
        // convert into a specific workable format
        const paragraphHTML = prepareParagraph(paragraph, sentence, DELIM)
        .split(DELIM)
        const sentencePosition = findSentenceIndex(paragraphHTML)
        for (let i = 0; i < paragraphHTML.length; i += 1) {
            const value = paragraphHTML[i]
            if (i === sentencePosition) {
                // insert in word, along with badge html
                paragraphHTML[i] = sentenceHTML(wordToHighlight, sentence)
            } else {
                // wrap with low opacity span
                paragraphHTML[i] = nonSentenceHTML(value)
            }
        }
        return {
            ...aSentence,
            html: paragraphHTML.join('')
        }
    })
}
