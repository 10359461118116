<template>
    <div class="modal fade" id="custom-word-modal" tabindex="-1" role="dialog" aria-labelledby="custom-word-modal"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 style="justify-content:left">
                        <span class="badge c-white" :class="flags.cardType == 'vocab' ? 'bg-primary' : 'bg-secondary'"
                            @click="flags.cardType = 'vocab'">Custom Vocab</span>
                        <span class="badge c-white" :class="flags.cardType == 'kanji' ? 'bg-primary' : 'bg-secondary'"
                            @click="[flags.cardType = 'kanji', obj.kanji = '']">Text to Kanji</span>
                        <span class="badge c-white" :class="flags.cardType == 'quick_kanji' ? 'bg-primary' : 'bg-secondary'"
                            @click="[flags.cardType = 'quick_kanji', setTextAsLessons()]">Lessons to Kanji</span>
                    </h2>
                    <span class="fa-stack" data-bs-dismiss="modal" aria-label="Close" style="font-size: 1em">
                        <i class="fa-stack-2x fas fa-square"></i>
                        <i class="fas fa-times fa-stack-1x"></i>
                    </span>
                </div>
                <div v-show="flags.cardType == 'vocab'" class="modal-body">
                    <div class="i-group w-50">
                        <div><span class="badge bg-danger">Word</span></div>
                        <input v-model="obj.word" placeholder="Enter a kanji" />
                    </div>
                </div>
                <div v-show="flags.cardType == 'vocab' && obj.word" class="modal-body">
                    <div class="i-group w-75 marg-small">
                        <div><span class="badge bg-success">reading</span></div>
                        <input id="reading_field" @focus="bindField('reading_field')" @blur="unbindField('reading_field')"
                            @keyup="fixWanakanaField('reading_field')" v-model="obj.reading"
                            placeholder="Enter reading(s). Separate readings with a ;" />
                    </div>
                    <div class="i-group w-75 marg-small">
                        <div><span class="badge bg-success">definition</span></div>
                        <input v-model="obj.definition" placeholder="Enter definition(s). Separate definitions with a ;" />
                    </div>
                    <span class="badge bg-info">
                        notes
                    </span>
                    <span class="badge bg-info" v-tippy
                        content="Notes will appear after you answer a question. You can enclose a word or phrase with * or ** to highlight certain phrases.<br/>Ex: This is a *highlighted phrase*. And **this** as well.">
                        <i class="far fa-question-circle"></i>
                    </span>
                    <span v-if="obj.notes && !flags.editingNotes" class="badge bg-secondary"
                        style="white-space: normal; text-align:left;" v-html="$convertNoteToHtml">
                    </span>
                    <span v-if="!obj.notes && !flags.editingNotes" class="badge bg-secondary">
                        none
                    </span>
                    <span class="badge bg-success">
                        <i class="fas fa-edit" @click="() => { flags.editingNotes = true }"></i>
                    </span>
                    <textarea id="notes_field" v-show="flags.editingNotes" class="w-100" style="margin-left:.5em;"
                        :value="obj.notes" @blur="handleNotesChanges()" @keyup.stop>
                </textarea>
                </div>
                <div v-if="flags.cardType !== 'vocab'" class="modal-body">
                    <div class="row marg-big">
                        <div class="col-12 mx-auto">
                            <div class="marg-big">
                                <span class="badge bg-info">
                                    <i class="fas fa-question-circle"></i>
                                </span>
                                <span v-if="flags.cardType == 'kanji'">
                                    Type a kanji, multiple kanji or a word. All kanji included will be extracted and added
                                    as individual cards.
                                </span>
                                <span v-else>
                                    Type a kanji, multiple kanji or a word. All kanji included will be extracted and added
                                    as individual cards. The following text has been taken from your lessons.
                                </span>
                            </div>
                            <div class="i-group w-100">
                                <div>
                                    <span class="badge bg-danger">Text</span>
                                </div>
                                <input v-model="obj.kanji" />
                            </div>
                        </div>
                        <div v-if="extracted.length > 0" class="col-12 mx-auto">
                            <div class="marg-big">
                                <span class="badge bg-danger"><i class="fas fa-flask"></i></span> The following kanji have
                                been extracted from the text. Click any kanji to selected it, then click the plus button at
                                the bottom. Any kanji already on your account will be ignored.
                            </div>
                            <div class="marg-big">
                                <span class="badge clickable kanji-badge"
                                    :class="!ignored.includes(items) ? 'bg-danger' : 'bg-secondary'"
                                    v-for="(items, indx) in extracted" :key="indx + 'b'" @click="toggleIgnore(items)">
                                    {{ items }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <!-- errors with custom word -->
                    <div v-if="flags.cardType == 'vocab' && $hasErrors.length > 0">
                        <span v-for="(items, indx) in $hasErrors" :key="indx + 'c'" class="badge bg-danger">{{ `${items} `
                        }}<i class="fas fa-exclamation-triangle"></i>
                        </span>
                    </div>
                    <!-- add custom word -->
                    <div v-if="flags.cardType == 'vocab' && $hasErrors.length == 0" class="clickable" @click="addWord()"
                        v-tippy content="Create vocabulary card">
                        <span class="fa-stack">
                            <i class="fa-stack-2x fas fa-square c-purple"></i>
                            <i class="fa-stack-1x fas fa-plus"></i>
                        </span>
                    </div>
                    <!-- add kanji -->
                    <div v-if="flags.cardType != 'vocab'" class="clickable" @click="addKanji(extracted, ignored)" v-tippy
                        content="Create cards for selected kanji">
                        <span class="fa-stack">
                            <i class="fa-stack-2x fas fa-square c-red"></i>
                            <i class="fa-stack-1x fas fa-plus"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    bind, unbind, isKanji, isJapanese
} from 'wanakana'
import C from '@/assets/common'
import ApiPreloader from '@/assets/preloader'
import {
    readCustomMarkup
} from '@/features/render/index'

const {
    UseAPI, HandleRequestFail, SendUserAlert
} = C
const wanakana = {
    isKanji, isJapanese, bind, unbind
}

export default {
    name: "CustomWord",
    props: [
        'words'
    ],
    data() {
        return {
            obj: {
                word: '',
                definition: '',
                reading: '',
                notes: '',
                kanji: '', /* not same as word */
            },
            ignored: [],
            extracted: [],
            flags: {
                editingNotes: false,
                editingReading: false,
                editingDefinition: false,
                cardType: 'vocab'
            }
        }
    },
    computed: {
        $hasErrors() {
            const errors = []
            if (!wanakana.isJapanese(this.obj.word)) errors.push('Word must be Japanese')
            const readingRegex = this.obj.reading.replace(/;/g, '')
            console.log('readingRegex', readingRegex)
            const readingIsJapanese = wanakana.isJapanese(readingRegex)
            if (readingRegex && !readingIsJapanese) errors.push('Reading should be in Japanese')
            if (!this.obj.definition) errors.push('Requires at least one definition')
            return errors
        },
        $convertNoteToHtml() {
            if (!('notes' in this.obj) || !(this.obj.notes)) {
                return ""
            }
            return readCustomMarkup(this.obj.notes)
        }
    },
    created() {
        console.log('made component comp_search', this)
    },
    watch: {
        obj: {
            deep: true,
            handler(arr) {
                if (this.flags.cardType === 'vocab') { return false }
                if (arr.kanji) {
                    const text = arr.kanji.split('')
                    const extr = new Set(text.filter((i) => wanakana.isKanji(i)))
                    this.extracted = Array.from(extr)
                    // auto ignore all so people can just select things
                    this.ignored = this.extracted.map((i) => i)
                    console.log('ran extract')
                    return true
                }
                return false
            }
        }
    },
    methods: {
        bindField(id) {
            const input = document.getElementById(id)
            wanakana.bind(input)
        },
        unbindField(id) {
            const input = document.getElementById(id)
            wanakana.unbind(input)
        },
        fixWanakanaField(id) {
            //  wanakana will change the field value but this change will not be registered in v-models
            //  this will just rechange it
            const input = document.getElementById(id)
            window.setTimeout(() => {
                console.log('running fixwanakanafield')
                const val = input.value
                this.obj.reading = val
            }, 150)
        },
        handleNotesChanges(val = document.getElementById('notes_field').value) {
            const { notes } = this.obj
            const item = this.obj
            if (val === notes) {
                this.flags.editingNotes = false
                return
            }
            if (!confirm('Save changes?')) {
                this.flags.editingNotes = false
                return
            }
            this.flags.editingNotes = false
            item.notes = val
        },
        toggleModal(typ) {
            typ === 'vocab' ? this.flags.cardType = 'vocab' : this.flags.cardType = 'kanji'
        },
        setKanji(val = "") {
            const x = val || prompt("Type in the word", this.obj.word)
            if (x) {
                this.obj.word = x
            }
            return x
        },
        addTo(key) {
            const x = prompt('Type in something to add. Add multiple at once by splitting readings with a ;')
            if (x) {
                const item = this.obj[key].split(';')
                x.split(';').forEach((i) => {
                    item.push(i)
                })
                if (item[0] === "") item.splice(0, 1)
                this.obj[key] = item.join(';')
            }
        },
        deleteFrom(key, val) {
            const item = this.obj[key].split(';')
            const found = item.findIndex((i) => i === val)
            console.log('foudn', found)
            if (found !== null) item.splice(found, 1)
            this.obj[key] = item.join(';')
        },
        toggleIgnore(item) {
            const pos = this.ignored.findIndex((i) => i === item)
            pos !== -1 ? this.ignored.splice(pos, 1) : this.ignored.push(item)
            console.log('ignored list is ', this.ignored)
        },
        setTextAsLessons(item) {
            const words = this.words.map((i) => i.word)
            this.obj.kanji = words.join(',')
            return true
        },
        async addWord() {
            // THIS IS COPIED FROM list_methods.js addExact()
            if (this.obj.word.length <= 0) { alert('No word inputted'); return false; }
            if (!wanakana.isJapanese(this.obj.word.replace(/[0-9]/g, ''))) { alert('The word isnt Japanese'); return false; }
            if (this.obj.reading.length <= 0 && this.obj.definition.length <= 0) { alert('Input either a definition or reading'); return false; }
            const finalItem = {
                card: this.obj.word,
                q_state: 0,
                readings: this.obj.reading,
                synonyms: this.obj.definition,
                notes: this.obj.notes
            }
            finalItem.readings = finalItem.readings.toString()
            const finalData = {
                item: finalItem,
            }
            return UseAPI('/create/add-custom', { method: "PUT", body: JSON.stringify(finalData) })
                .then((dat) => {
                    console.log(dat)
                    if ('failure' in dat && dat.failure.length > 0) throw new Error('duplicate')
                    SendUserAlert('Word added', 'alert-success');
                    this.$emit('hide-modal')
                    this.$emit('force-refresh')
                    // also clear preload data
                    ApiPreloader.clearApiKey('/lessons')
                },)
                .catch((dat) => {
                    console.log('ERROR LOG:')
                    console.log(dat)
                    alert('Failed to add word. Most likely a duplicate, but open browser developer console for details.')
                })
        },
        async addKanji(listOfKanji, ignoredList) {
            const items = []
            if (listOfKanji.filter((i) => !ignoredList.includes(i)).length === 0) {
                SendUserAlert('No kanji selected', 'alert-danger');
                return false
            }
            listOfKanji.forEach((entry) => {
                if (wanakana.isKanji(entry) && !ignoredList.includes(entry)) {
                    items.push({
                        card: entry,
                        q_state: 0,
                        readings: entry
                    })
                }
            })
            const finalData = {
                items,
                match_type: 'kanji',
                source: 'custom kanji'
            }
            return UseAPI('/create/add-kanji', { method: "PUT", body: JSON.stringify(finalData) })
                .then((dat) => {
                    const { failure, created = [] } = dat.modules
                    console.log(dat, failure, created)
                    if (failure && failure.length > 0) SendUserAlert(`Failed to add ${failure.length} of ${dat.options.items.length} kanji. Likely duplicates.`, 'alert-danger')
                    if (created && created.length > 0) SendUserAlert(`Added ${created.length} kanji.`)
                    this.$emit('hide-modal')
                    this.$emit('force-refresh')
                },)
                .catch((dat) => {
                    HandleRequestFail(dat)
                })
        },
    },
}
</script>

<style lang="sass" scoped>
#custom-word-modal
    .modal-body
        font-size: 1.2rem
        input
            font-size: .75em
    .kanji-badge
        min-width: 3em
</style>
