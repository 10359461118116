<template>
    <div class="loading spinner">
        <i class="fas fa-3x fa-spinner fa-pulse"></i>
        <div v-if="message" class="msg-cont">
            <span class="badge msg" :class="badge">
                {{message}}
            </span>
        </div>
    </div>
</template>

<script>
/*  build pattern:
    <div class="loadable">
        <Spinner/>
        <div :gettingloaded="someCondition ? 1 : 0">
*/
export default {
    name: 'Spinner',
    props: {
        message: {
            type: String,
            default() {
                return ''
            }
        },
        badge: {
            type: String,
            default() {
                return 'badge-jet'
            }
        }
    }
}
</script>

<style lang="sass" scoped>
.spinner
    display: flex
    flex-direction: column
    align-items: center
    i
        color: #3c3c3c!important
    .msg-cont
        text-align: center
        margin-top: 1em
        .msg
            font-size: 16px
body.dark
    .spinner
        i
            color: white!important
</style>
