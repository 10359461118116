<template>
    <div id="search" v-click-outside="close">
        <ul style="margin:0; padding: 0">
            <li class="search-bar" style="position:relative;">
                <div class="i-group shaded w-100 mx-auto">
                    <div>
                        <i class="fas fa-search"></i>
                    </div>
                    <div class="main">
                        <input v-model="searchFld" class="w-100" placeholder="Search with romaji or Japanese"
                            autocomplete="off" autofocus="off" @focus="showSearch = true" />
                        <i class="search-active-indicator fas" :class="searchStatusIndicator"></i>
                    </div>
                    <slot name="custom-slot" v-bind:close="close"></slot>
                </div>
                <!-- show results of search -->
                <ul v-show="showSearch" class="autocomplete-ui w-100" @mouseEnter="searchHovering = true"
                    @mouseLeave="searchHovering = false" :style="searchFld.length === 0 ? 'padding: 0; border: 0' : ''">
                    <li v-if="searchResults.length == 0 && searchFld.length > 0">
                        <div>
                            <span class="search-secondary" style="margin-left: 0">No results</span>
                        </div>
                    </li>
                    <li v-for="(items, indx) in searchResults" :key="indx + 'b'">
                        <div>
                            <span lang="ja" class="search-primary" :class="items.card_type == 'v' ? 'c-purple' : 'c-red'">{{
                                items.kanji }}</span>
                            <span class="search-secondary">
                                <span v-if="items.pronunciation" role="pronunciation" lang="ja"
                                    v-html="fixPronunciationHTML(items.pronunciation)"></span>
                                <span v-else>
                                    <span role="pronunciation" lang="ja"
                                        v-html="items.pronunciation || items.reading[0]"></span>
                                    <span lang="ja" v-if="items.reading.length > 1" style="opacity:.65">{{ items.reading[1]
                                    }}</span>
                                </span>
                            </span>
                            <span class="badge bg-success"
                                v-for="(pos, indx) in items.pos.filter((i) => ['uk', 'hum', 'hon', 'aux'].includes(i))"
                                :key="indx">
                                {{ pos }}
                            </span>
                            <span class="badge bg-danger"
                                v-for="(pos, indx) in items.pos.filter((i) => ['arch', 'vulg', 'rare'].includes(i))"
                                :key="indx + 'a'">
                                {{ pos }}
                            </span>
                            <!-- badges -->
                            <span v-if="items.known && items.queue_state < 99" class="badge bg-success">{{
                                'lv' + items.queue_state }}</span>
                            <span v-else-if="items.known && items.queue_state == 99" class="badge bg-success">known</span>
                            <!-- if has inList -->
                            <span v-if="items.freq" class="badge bg-purple">freq {{ items.freq }}</span>
                            <!-- buttons -->
                            <span v-if="items.known && items.queue_state != 99" class="fa-stack clickable align-center"
                                @click="handleTrash(items.cardId)" v-tippy content="mark as known">
                                <i class="fa-stack-2x fas fa-square c-purple"></i>
                                <i class="fa-stack-1x fas fa-trash"></i>
                            </span>
                            <span v-if="items.known" class="fa-stack clickable align-center"
                                @click="handleDelete(items.cardId)" v-tippy content="delete item">
                                <i class="fa-stack-2x fas fa-square c-purple"></i>
                                <i class="fa-stack-1x fas fa-times"></i>
                            </span>
                            <span v-if="!items.known" class="fa-stack clickable align-center" @click="handleAdd(items)"
                                v-tippy content="add word">
                                <i class="fa-stack-2x fas fa-square"
                                    :class="items.card_type === 'k' ? 'c-red' : 'c-purple'"></i>
                                <i class="fa-stack-1x fas fa-plus"></i>
                            </span>
                            <span v-if="items.position" class="fa-stack clickable align-center"
                                @click="[showSearch = false, searchHovering = false, $emit('jump-to-item', items.position)]"
                                v-tippy content="jump to word">
                                <i class="fa-stack-2x fas fa-square c-green"></i>
                                <i class="fa-stack-1x fas fa-directions"></i>
                            </span>
                        </div>
                        <!-- definitions -->
                        <span class="search-secondary">
                            {{ items.definition.slice(0, 2).map((i) => i.length > 33 ? i.substring(0, 30) + '...' : i) }}
                        </span>
                        <!-- pos -->
                        <span class="search-secondary">
                            {{ items.pos.filter((i) => !['uk', 'hum', 'hon', 'aux', 'arch',
                                'vulg'].includes(i)).toString().replace(/,/g, ', ') }}
                        </span>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
import {
    isJapanese, isMixed, toHiragana, toKatakana
} from 'wanakana';
import { UseAPI, HandleRequestFail } from '@/assets/common'
import { SS } from '@/assets/constants'

const wanakana = {
    isJapanese, isMixed, toHiragana, toKatakana
}

export default {
    name: 'LessonsSearch',
    created() {
        console.log('%cSearch created', window.ConsoleStyles.createdComponent, this)
        const vm = this
        //  too bad I dont know how to implement this into Vue
        // remove if you've confirmed v-click-outside is stable
        /* $(document).mouseup((e) => {
            const container = [];
            container.push($('#search'));
            $.each(container, (key, value) => {
                if (!$(value).is(e.target) // if the target of the click isn't the container...
                    && $(value).has(e.target).length === 0) { // ... nor a descendant of the container
                    vm.showSearch = false
                }
            });
        }); */
    },
    computed: {
        searchFldConverted() {
            const { searchFld } = this
            //  handle unexpected behavior that may happen with conversions
            const convertToJapanese = (val, func) => {
                const hasDoubleN = val.search('nn') !== -1
                let n = val
                if (hasDoubleN) n = n.replace('nn', 'ん')
                return func(n).replace(/[a-zA-Z]/g, '')
            }
            const obj = {
                eng: searchFld,
                hiragana: !wanakana.isJapanese(searchFld) ? convertToJapanese(searchFld, wanakana.toHiragana) : searchFld,
                katakana: !wanakana.isJapanese(searchFld) ? convertToJapanese(searchFld, wanakana.toKatakana) : searchFld
            }
            console.log('search field converted:', obj.eng, obj.hiragana, obj.katakana)

            return obj
        },
        searchStatusIndicator() {
            if (this.searching) return 'fa-spinner o-75 fa-spin'
            if (this.searchResults.length === 0) return ''
            if (this.showSearch) return 'fa-eye o-50';
            return 'fa-eye-slash o-25'
        }
    },
    methods: {
        startSearch(val) {
            if (!val || !this.searchFldConverted.hiragana) {
                this.searchResults = []
                return true
            }
            //  if (!wanakana.isJapanese(this.searchFldConverted.hiragana)) return this.searchReq(this.searchFldConverted.eng, false)
            return this.searchReq(this.searchFldConverted.hiragana, true)
        },
        searchReq(str, jp = true) {
            const dat = new Date();
            const time = dat.getTime();
            const endpoint = jp ? "/get/lookup-jp" : "/get/lookup-en"
            if (this.searchTimer) {
                window.clearTimeout(this.searchTimer)
                console.log('timer cleared')
            }
            const hasList = this.list ? `&list=${this.list}` : ''
            const method = SS.get(SS.PUBLIC_API) ? "GET" : "POST"
            const req = () => {
                this.searching = true
                UseAPI(endpoint, { method, queryParameters: `word=${str}&timestamp=${time}${hasList}` })
                    .then((data) => {
                        this.searching = false
                        this.handleSearchReq(data)
                        if (this.debug) console.log(data)
                    })
                    .catch((data) => {
                        HandleRequestFail(data)
                        console.log('error log')
                        console.log(JSON.stringify(data))
                    });
                this.searchTimer = null
            }
            this.searchTimer = window.setTimeout(req, 200)
            return req
        },
        handleSearchReq(dat) {
            // timestamp check
            if ('options' in dat === false
                || !('timestamp' in dat.options)
                || this.searchTimestamp > dat.options.timestamp
            ) return
            if ('word' in dat.options && !wanakana.isJapanese(dat.options.word)) return
            const addPronunciations = (arr) => {
                const { pronunciation } = arr.modules
                arr.items.forEach((item) => {
                    const found = pronunciation.items.findIndex(
                        (pro) => pro.word === item.kanji && wanakana.toHiragana(pro.reading) === item.reading
                    )
                    if (found !== -1) {
                        item.pronunciation = pronunciation.items[found].pronunciation
                    } else {
                        item.pronunciation = null
                    }
                })
            }
            this.searchTimestamp = dat.options.timestamp
            const final = []
            const searchedTerm = dat.options.word
            const words = dat.items
            addPronunciations(dat)
            words.forEach((item) => {
                'pos' in item
                    ? item.pos = item.pos.split(" ")
                    : item.pos = []
                // put in bar so you know where it deviated from exact search
                item.reading = item.reading.replace(searchedTerm, `${searchedTerm}|`).split('|')
                if (item.somethingelse <= 0) item.pos.push('rare')
                item.definition = item.definition.split(',')
                final.push(item)
            })
            this.searchResults = final
        },
        fixPronunciationHTML(pronunciationHTML) {
            return pronunciationHTML.replace(/class="nasal"/, `class="nasal" style="color: grey"`)
        },
        handleDelete(itemId) {
            if (!window.confirm('Do you want to delete this item?')) {
                return false
            }
            this.$emit('req-delete', [itemId])
            this.startSearch(this.searchFld)
            return true
        },
        handleTrash(itemId) {
            if (confirm('Do you want to trash this item?')) this.$emit('req-trash', [itemId])
        },
        handleAdd(items) {
            if (!window.confirm('Do you want to add this item?')) {
                return false
            }
            if (items.card_type === 'v') {
                return this.startAdd(items)
                    .then((res) => {
                        this.$emit('refresh-list')
                    })
            }
            return this.addKanji(items.kanji)
                .then((res) => {
                    this.$emit('refresh-list')
                })
        },
        startAdd(item, q_state = 0) {
            // THIS IS COPIED FROM list_methods.js addExact()
            const finalItem = {
                card: item.kanji,
                readings: item.reading.join('')
            }
            finalItem.readings = finalItem.readings.toString()
            const finalData = {
                items: [finalItem],
                match_type: 'exact',
                source: 'dictionary lookup'
            }
            return UseAPI('/create/add-exact', {
                method: "PUT",
                body: JSON.stringify(finalData)
            })
                .then((dat) => {
                    console.log(dat)
                    if (this.searchFld !== '') this.startSearch(this.searchFld)
                    this.startSearch(this.searchFld)
                    //  this doesnt do anything unless you tell it to
                    this.$emit('req-add', dat)
                })
                .catch((dat) => {
                    HandleRequestFail(dat)
                })
        },
        addKanji(kanji, q_state = 0) {
            const finalItem = {
                card: kanji,
                readings: kanji
            }
            const finalData = {
                items: [finalItem],
                match_type: 'kanji',
                source: 'dictionary lookup'
            }
            return UseAPI('/create/add-kanji', { method: "PUT", body: JSON.stringify(finalData) })
                .then((dat) => {
                    console.log(dat)
                    if (this.searchFld !== '') this.startSearch(this.searchFld)
                    this.startSearch(this.searchFld)
                })
                .catch((dat) => {
                    HandleRequestFail(dat)
                })
        },
        close() {
            this.showSearch = false
        }
    },
    watch: {
        searchFld(val) {
            console.log('detected change in searchFld', val, this.searchFldConverted)
            this.startSearch(val)
        },
    },
    props: {
        list: {
            default() {
                return ""
            }
        }
    },
    data() {
        return {
            searchFld: "",
            searchResults: [],
            searching: false,
            searchHovering: false,
            showSearch: false,
            searchTimestamp: 0,
            searchTimer: null,
            debug: true
        }
    }
}
</script>

<style lang="sass" scoped>
.search-bar, .search-bar ul
    list-style-type: none
.search-bar
    position: relative
    .autocomplete-ui
        position: absolute
        left: 0
        top: 0
        padding: 0
        margin-top: 4.2rem
        padding-top: .5rem
        background-color: white
        border: 1px solid darkgrey
        border-top-color: transparent
        z-index: 99
        max-height: 30em
        overflow-y: auto
        span.fa-stack
            font-size: .85rem
            margin: 0 .5em
        li
            padding: 1px .25em
            &:hover
                background-color: #eee
    .search-primary
        font-weight: bold
        min-width: 300px
    .search-secondary
        margin-left: 1em
        color: grey
        *[lang="ja"]
            color: #434343!important
    .search-add
        position: absolute
        height: 100%
        right: .5em
        display: flex
        align-items: center
        display: none
    &:focus-within
        .search-add
            display: inline
    .badge
        margin-left: .5em
    .main
        position: relative
    .search-active-indicator
        position: absolute
        right: 1em
</style>
